import { extendTheme } from '@chakra-ui/react';

import { Button } from './components/Button';
import { Text } from './components/Text';
import { Heading } from './components/Heading';
import { FormLabel } from './components/FormLabel';
import { Radio } from './components/Radio';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { tagTheme } from './components/tag';

const layerStyles = {};

export const theme = extendTheme({
  colors,
  fonts: {
    body: "'Manrope', sans-serif",
    heading: "'Manrope', sans-serif",
  },
  shadows: {},
  breakpoints,
  layerStyles,
  components: {
    Button,
    Text,
    FormLabel,
    Heading,
    Radio,
    Tag: tagTheme,
  },
  styles: {
    global: () => ({
      '#nprogress .bar ': {
        background: 'primary.500',
      },
      '#nprogress .peg ': {
        boxShadow: '0 0 10px #56B6A5, 0 0 5px #56B6A5',
      },
    }),
  },
});
