export interface IBreakpoints {
  [x: string]: string;
}

export const breakpoints: IBreakpoints = {
  xs: '0',
  ux : "300px",
  sx : "350px",
  sm: '480px',
  md: '768px',
  lg: '1025px',
  xl: '1440px',
  '2xl': '1920px',
};
