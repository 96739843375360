export const Text = {
  baseStyle: {
    color: 'text.black',
  },
  variants: {
    heroDesc: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      color: 'white',
    },
    featureText: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '21px',
      color: 'text.mediumBlack',
    },
    calendarDesc: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      color: 'white',
    },
    month: {
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '19.6px',
      color: 'text.mediumBlack',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
    },
    tabsDesc: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      color: 'text.mediumBlackAlpha',
    },
    formLabel: {
      fontSize: '15px',
      fontWeight: '800',
      lineHeight: '22.5px',
      letterSpaciing: '-0.02em',
      color: 'text.darkGray',
    },
    contentText: {
      fontWeight: '600',
      fontSize: '16px',
      letterSpacing: '0.02em',
      color: 'text.headingWhite',
      lineHeight: '150%',
    },
    infoText: {
      fontWeight: '800',
      fontSize: '20px',
      letterSpacing: '-0.02em',
      color: 'text.textBlue',
      lineHeight: '150%',
    },
    headingText: {
      fontWeight: '800',
      fontSize: { xs: '34px', sm: '48px', lg: '54px' },
      letterSpacing: '-0.03em',
      color: 'text.headingBlue',
      lineHeight: { xs: '130%', sm: '120%', lg: '130%' },
    },
    queryCardPersonRole: {
      fontSize: '15px',
      fontWeight: '600',
      lineHeight: '22.5px',
      letterSpaciing: '-0.02em',
      color: 'text.veryLightGrey',
    },
    disclaimerText: {
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '22.5px',
      color: 'text.mediumBlackAlpha',
      textAlign: 'justify',
    },
    dropdownHeading: {
      fontSize: '16px',
      fontWeight: '800',
      lineHeight: '24px',
      color: 'text.mediumBlack',
    },
    dropdownSubLink: {
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '21px',
      letterSpacing: '-0.02em',
      color: 'text.mediumBlackAlpha',
      _hover: { textDecoration: 'none', color: 'primary.500' },
    },
    subLinks: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '21px',
      letterSpacing: '-0.02em',
      color: 'text.greyishBlack',
      _hover: { textDecoration: 'none', color: 'primary.500' },
    },
    eventNum: {
      fontSize: '45px',
      fontWeight: '600',
      lineHeight: '63px',
      color: 'white',
    },
    vcText: {
      color: '#004069',

      lineHeight: '1.75',
      fontSize: '18px',
    },
    aboutText: {
      fontSize: '1.15rem',
      color: 'text.darkHead',
    },
    blogAnswer: {
      fontSize: '18px',
      fontWeight: '500',
      color: 'text.mediumBlack',
      lineHeight: '28.5px',
      textAlign: 'justify',
    },
  },
  defaultProps: {},
};
