export const Heading = {
  baseStyle: {
    color: 'text.black',
  },
  variants: {
    hero: {
      fontSize: '74px',
      fontWeight: '800',
      color: 'white',
    },
    featureTitle: {
      fontSize: ['14px', '16px', '20px'],
      fontWeight: '800',
      lineHeight: '30px',
      color: 'black',
    },
    footerTitle: {
      fontSize: '16px',
      fontWeight: '800',
      lineHeight: '24px',
      color: 'text.mediumBlack',
      textTransform: 'uppercase',
    },
    infoDetails: {
      fontSize: '15px',
      fontWeight: '800',
      lineHeight: '22.5px',
      color: 'text.mediumBlack',
    },
    largeInfo: {
      fontSize: ['40px', '48px', '65px'],
      fontWeight: '500',
      lineHeight: ['70px', '80px', '91px'],
      letterSpacing: '-0.03em',
      color: 'text.purple',
    },
    milestone: {
      fontSize: '24px',
      fontWeight: '800',
      lineHeight: '28.5px',
      color: 'text.darkGray',
    },
    heroNum: {
      fontSize: '15px',
      fontWeight: '800',
      lineHeight: '22.5px',
      letterSpacing: '-0,02em',
      color: 'white',
    },
    calendarTitle: {
      fontSize: '42px',
      fontWeight: '800',
      lineHeight: '54.6px',
      color: 'white',
    },
    eventTitle: {
      fontSize: '18px',
      fontWeight: '800',
      lineHeight: '27px',
      color: 'text.mediumBlack',
    },
    headingText: {
      fontWeight: '800',
      fontSize: { xs: '34px', sm: '48px', lg: '54px' },
      letterSpacing: '-0.03em',
      color: 'text.headingBlue',
      lineHeight: { xs: '130%', sm: '120%', lg: '130%' },
    },
    date: {
      fontSize: '42px',
      fontWeight: '800',
      lineHeight: '54.6px',
      color: 'text.mediumBlack',
    },
    membershipTitle: {
      fontSize: '54px',
      fontWeight: '800',
      lineHeight: { xs: '50px', md: '60px', lg: '70.2px' },
      letterSpacing: '-0.03em',
      color: 'text.darkHead',
    },
    queryCardInfo: {
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '27px',
    },
    queryCardName: {
      fontSize: '22px',
      fontWeight: '800',
      lineHeight: '33px',
      color: 'white',
    },
    reportCardTitle: {
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '28px',
      color: 'text.mediumBlack',
      letterSpacing: '-0.01em',
    },
    cardDate: {
      fontSize: '14px',
      fontWeight: '800',
      lineHeight: '21px',
      color: 'text.greyPurple',
      letterSpacing: '-0.02em',
    },
    mailDetails: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '21px',
      color: 'white',
    },
    companyTitle: {
      fontSize: '20px',
      fontWeight: '800',
      lineHeight: '30px',
      color: 'text.veryDarkPurple',
    },
    smallText: {
      fontSize: '15px',
      fontWeight: '700',
      lineHeight: '22.5px',
      color: 'text.mediumBlackAlpha',
    },
    queryContact: {
      fontSize: '30px',
      fontWeight: '800',
      lineHeight: '39px',
      color: 'white',
    },
    blogQuestion: {
      fontSize: '18.4px',
      fontWeight: '700',
      lineHeight: '28px',
      color: 'text.darkHead',
      textAlign: 'justify',
    },
    loginHeading: {
      fontWeight: '700',

      lineHeight: '150%',

      letterSpacing: '-0.01em',

      color: 'text.headingBlue',
    },
  },
  defaultProps: {},
};
