import '@/styles/globals.css';
import '@/styles/progressbar.css';
import 'react-calendar/dist/Calendar.css';
import '@/styles/calendar.css';
import React from 'react';
import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Script from 'next/script';
import { LevoMembership } from '@levo-so/membership';
import dynamic from 'next/dynamic';

import { theme } from '@/theme';
import { store } from '@/store/index';
import TopProgressBar from '@/components/Progressbar';

const LevoAudiencePage = dynamic(
  () => import('@levo-so/audience').then((m) => m.LevoAudiencePage),
  { ssr: false }
);

if (process.env.NEXT_PUBLIC_LEVO_WORKSPACE) {
  LevoMembership.init({
    workspace: process.env.NEXT_PUBLIC_LEVO_WORKSPACE,
    options: {
      url: '/.levo/api',
      version: 'v1',
    },
  });
}

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => (
  <QueryClientProvider client={queryClient}>
    {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS && (
      <>
        <Script
          strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />
        <Script
          id="gtag-code"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </>
    )}
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <TopProgressBar />
        <LevoAudiencePage
          workspace_id={process.env.NEXT_PUBLIC_LEVO_WORKSPACE || 'WGLBUT82'}
        />
        <Component {...pageProps} />
      </Provider>
    </ChakraProvider>
  </QueryClientProvider>
);

export default MyApp;
