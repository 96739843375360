export const colors = {
  primary: {
    50: '#d3ece7',
    100: '#afdcd4',
    200: '#9dd5cb',
    300: '#8bcdc1',
    400: '#7ac5b8',
    500: '#56B6A5',
    600: '#419888',
    700: '#3a8678',
    800: '#327468',
    900: '#235048',
  },
  secondary: {
    50: '#cacad9',
    100: '#acacc4',
    200: '#9d9db9',
    300: '#8e8eae',
    400: '#7f7fa4',
    500: '#64648C',
    600: '#4f4f6e',
    700: '#44445f',
    800: '#3a3a51',
    900: '#242433',
  },
  secondaryPurple: {
    100: '#F5F5FF',
    200: '#EAEAFF',
    600: '#8C8CEC',
    700: '#707CC0',
    900: '#232343',
  },
  whiteShade: '#FFFFFF75',
  lightGrey: '#8A8A9F',
  lightGreyContrast: '#47475e',
  lightWhite: '#FAFAFA',
  bg: {
    badge: '#E7E2FF',
    section: 'rgba(217, 217, 217, 0.13)',
    card: '#3F53E9',
    page: '#F3F4F6',
    mediumWhite: '#F7F7FB',
    lightWhite: '#F6F6F8',
    darkGrayAlpha: '#64648C45',
    primaryLight: '#EAFFFB',
    gray: '#DCE3E9',
    borderBg: '#EDEDED',
    lightGrey: '#F5F5FF',
    deepGray: '#F5F5F5',
    veryLightWhite: '#FFFFFF75',
    minLightWhite: '#464666',
    minLightWhiteTwo: '#494969',
    primaryMedium: '#A8A8FD',
    darkGrey: '#E9E9F3',
    veryLightGray: '#D9D9D94D',
    veryLightGrey: '#EAEAEA',
    veryLightBlack: '#64648C29',
    lightBlack: '#DDDDF3',
    purpleBg: '#E1E1FF',
    barGraphBg: '#BFBFFF1C',
    queryHeadingBg: '#4D4D6C',
    navDropdownBorder: '#0000002B',
    lightGreyishWhite: '#bfbfff1c',
    veryGreyishBg: '#00000014',
    seperatorBg: '#64648c26',
    cardLightBlack: '#454565',

    grayishBlack: '#00000017',

    veryLightPrimary: '#EFFBF9',
  },
  text: {
    veryDarkPurple: '#B1BDFF',
    badge: '#373737',
    black: '#243547',
    heading: '#181818',
    mediumBlack: '#3D3D5F',
    mediumBlackAlpha: '#3D3D5FC7',
    purple: '#6E6EC7',
    lightPurple: '#6d6dc4',
    darkGray: '#64648c',
    lightDarkGray: '#64648C52',
    lightGray: '#8484BE',
    textGray: '#3C3C4380',
    darkHead: '#232343',
    placeholder: '#3D3D5F4A',
    lightPlaceholder: '#3D3D5FCC',
    headingWhite: '#FFFFFF',
    headingBlue: '#232343',
    textBlue: '#3D3D5F',
    textPurple: ' #64648C',
    green: '#56B6A5',
    contactCard: '#666682',
    veryLightPurple: '#70BFFF',
    veryLightGrey: '#FFFFFFB3',
    veryLightBlack: '#00000012',
    mediumDarkBlack: '#53538C',
    greyPurple: '#5869cc',
    greyishBlack: '#3D3D5F94',
    veryLightWhite: '#FFFFFFE6',
    textViolet: ' #595992',
  },
  errorRed: '#ff9d9d',
  customGray: {
    bg: '#f4f4f4',
    subtitle: '#7c7c7c',
  },
};
