export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    primary: {
      bg: 'primary',
      color: 'white',
      border: '1px solid',
      borderColor: 'primary',
    },
    secondary: {
      bg: 'primary.200',
      color: 'text.black',
    },
    heroBtn: {
      bgColor: 'white',
      color: 'black',
      p: ['16px 24px', '16px 34px'],
      fontSize: ['12px', '14px', '18px'],
      fontWeight: '700',
      lineHeight: '27px',
      borderRadius: '0',
    },
    cardButton: {
      py: '25px',
      bg: 'white',
      borderRadius: '0',
      fontSize: '15.0311px',
      fontWeight: '700',
      lineHeight: '150%',
      color: 'text.darkGray',
    },
  },
  defaultProps: {},
};
