export const Radio = {
  variants: {
    primary: ({ colorScheme = 'primary' }) => ({
      color: `${colorScheme}.500`,
      control: {
        _checked: {
          color: 'primary.500',
        },
      },
    }),
  },
  defaultProps: {
    variant: 'primary',
    colorScheme: 'primary',
  },
  formButton: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: 'text.lightPlaceholder',
  },
};
