import React, { useEffect, FC, useState } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { Box } from '@chakra-ui/react';

const TopProgressBar: FC<any> = () => {
  const [loading, setLoading] = useState(false);

  NProgress.configure({
    showSpinner: false,
  });

  const Router = useRouter();

  function load() {
    NProgress.start();
    setLoading(true);
  }

  function stop() {
    NProgress.done();
    setLoading(false);
    if (window) {
      window.scrollTo();
    }
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', load);
    Router.events.on('routeChangeComplete', stop);
    Router.events.on('routeChangeError', stop);

    return () => {
      Router.events.off('routeChangeStart', load);
      Router.events.off('routeChangeComplete', stop);
      Router.events.off('routeChangeError', stop);
    };
  }, []);

  return loading ? (
    <Box
      height="100%"
      width="100%"
      bg="black"
      zIndex="999"
      position="relative"
      opacity=".1"
    />
  ) : null;
};

export default TopProgressBar;
